import { graphql } from 'gatsby';
import React, { useState, useEffect, useMemo } from 'react';
import Layout from '~/components/global/Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import FacebookSVG from '~/assets/images/social/Facebook.svg';
import TwitterSVG from '~/assets/images/social/Twitter.svg';
import LinkedInSVG from '~/assets/images/social/Linkedin.svg';

import { Author } from '../flexible/Articles';
import Image from '../elements/Image';
import FlexibleContent from '../global/FlexibleContent';
import { ResourceGridInner } from '../resources/ResourceGrid';
import { SeoContainer } from '../global/SeoContainer';
import Wysiwyg from '../resources/Wysiwyg';
import { backGroundSwitcher } from '../utils/backgroundSwitcher';

export default function articlePage({
  data: { wpPage, wpPost, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const backgroundColour = backGroundSwitcher(wpPost?.postFlexibleContent?.masthead?.backgroundColor);
  const isImageBg = useMemo(() => wpPost?.postFlexibleContent?.masthead?.backgroundColor === "image", [wpPost]);
  const imageBg = useMemo(() => wpPost?.postFlexibleContent?.masthead?.image?.localFile?.publicURL || wpPost?.featuredImage?.node?.localFile?.publicURL, [wpPost]);
  const hasImage = !!(wpPost?.postFlexibleContent?.masthead?.image || wpPost?.featuredImage?.node) && !isImageBg;
  console.log('has image => ', hasImage)

  return (
    // set blogHeader true if backgroundColor is solid-blue to make header lightWhite
    <Layout wp={wp} blogHeader={backgroundColour === "bg-dark-purple text-white" || isImageBg}>
      <SeoContainer
        post={{
          ...wpPost,
          seo: {
            ...wpPost.seo,
            opengraphDescription: wpPost.seo.opengraphDescription || wpPost?.postFlexibleContent?.masthead?.description,
            opengraphImage: wpPost?.postFlexibleContent?.masthead?.image || wpPost?.featuredImage?.node || wpPost.seo.opengraphImage,
          },
        }}
        title={wpPost?.title}
      />

      <div className="p-2 mx-auto md:px-5 md:py-4">
        <div
          className={`${backgroundColour} relative rounded-md bg-cover bg-center`} 
          style={isImageBg ? {backgroundImage: `url(${imageBg})`, color: "white"} : {}}
        >
          <div className="noisy">
            <div className="container relative flex flex-wrap items-center pt-24 pb-16 md:flex-nowrap md:pt-60 md:pb-44 ">
              <div className={`relative w-full ${hasImage ? 'md:w-1/2' : ''}`}>
                <div className={`space-y-8 ${hasImage ? 'max-w-[545px]' : ''} md:pr-6 xl:pr-3`}>
                  <div className="space-y-6">
                    <h1 className="text-h2">{wpPost.title}</h1>
                    <div
                      className="p-large opacity-60"
                      dangerouslySetInnerHTML={{
                        __html: wpPost?.postFlexibleContent?.masthead?.description,
                      }}
                    />
                  </div>
                  <div className="mt-[26px]">
                    <Author className="opacity-100" author={wpPost?.author?.node?.name} date={wpPost?.date} />
                  </div>
                </div>
              </div>
              {hasImage && <div className="w-full mt-12 md:w-1/2 md:mt-z0">
                <div className="relative flex-none aspect-w-588 aspect-h-365 image-absolute ">
                  {wpPost?.postFlexibleContent?.masthead?.image || wpPost?.featuredImage?.node ? (
                    <Image
                      objectFit="cover"
                      image={wpPost?.postFlexibleContent?.masthead?.image || wpPost?.featuredImage?.node}
                      className="max-w-[587px] max-h-[365px] absolute w-full h-full"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full rounded bg-blue">
                      <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 26.5C0 26.9098 0.268683 27.0464 0.537365 27.0464C14.3772 29.7784 23.1113 37.5644 25.5298 52.4537C25.5298 52.7269 25.7985 53 26.0672 53C26.3359 53 26.4702 52.7269 26.4702 52.4537C28.889 37.5644 37.6228 29.7784 51.4626 27.0464C51.7313 27.0464 52 26.7732 52 26.5C52 26.2268 51.7313 25.9536 51.4626 25.9536C37.6228 23.2217 28.889 15.4356 26.4702 0.546391C26.4702 0.273197 26.2015 0 25.9328 0C25.6642 0 25.5298 0.273197 25.5298 0.546391C23.1113 15.4356 14.3772 23.2217 0.537365 25.9536C0.268683 25.9536 0 26.0902 0 26.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 md:mt-[87px]">
        <div className="container ">
          <div className="relative flex flex-wrap">
            <div className="flex-1 text-center md:w-2/12">
              <div className="sticky top-[7rem] flex flex-col w-full">
                <div className="font-bold text-[15px] leading-[1.65] mb-[10px] md:mb-[21px] ">Share</div>
                <ShareLinks />
              </div>
            </div>
            <div className="flex justify-center w-full md:w-10/12 article-content">
              {wpPost?.content && <Wysiwyg content={wpPost?.content} />}
              <FlexibleContent
                content={wpPost?.postFlexibleContent?.postFlexibleContent}
                prefix="Post_Postflexiblecontent_PostFlexibleContent_"
              />
            </div>
          </div>
        </div>
      </div>
      {wpPost?.postFlexibleContent?.relatedArticles?.articles && (
        <div className="bg-dark-purple">
          <div className="container py-[70px] md:py-[150px]">
            <div className="mx-auto">
              <h2 className="text-center text-h2">Related Articles </h2>
            </div>
            <ResourceGridInner posts={wpPost?.postFlexibleContent?.relatedArticles?.articles} />
          </div>
        </div>
      )}
    </Layout>
  );
}

export const ShareLinks = () => {
  const [shareURL, setShareURL] = useState(null);

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    url && setShareURL(url);
  }, []);

  return (
    <div className="flex mx-auto space-x-[10px] md:space-x-0 md:block mb-8 md:mb-0 md:space-y-[10px]">
      <div className="relative flex items-center justify-center w-12 h-12 transition-colors duration-300 ease-in-out bg-white rounded-full shadow-md cursor-pointer hover:border group banner-safari-fix social focus:outline-none ">
        <FacebookShareButton url={shareURL} className="absolute top-0 left-0 z-10 w-full h-full focus:outline-none " />
        <img src={FacebookSVG} alt="facebook" draggable="false" />
      </div>
      <div className="relative flex items-center justify-center w-12 h-12 transition-colors duration-300 ease-in-out bg-white rounded-full shadow-md cursor-pointer hover:border banner-safari-fix social focus:outline-none ">
        <TwitterShareButton url={shareURL} className="absolute top-0 left-0 z-10 w-full h-full focus:outline-none" />
        <img src={TwitterSVG} alt="twitter" draggable="false" />
      </div>
      <div className="relative flex items-center justify-center w-12 h-12 transition-colors duration-300 ease-in-out bg-white rounded-full shadow-md cursor-pointer hover:border banner-safari-fix social focus:outline-none ">
        <LinkedinShareButton url={shareURL} className="absolute top-0 left-0 z-10 w-full h-full focus:outline-none" />
        <img src={LinkedInSVG} alt="linkedin" draggable="false" />
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
      ...BlogSEO
      author {
        node {
          name
        }
      }
    }
    wp {
      ...GeneratedWp
    }
  }
`;
